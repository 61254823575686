<template>
  <div class="sales-group-setting">
    <PageTitle title="銷售群組設定" btn="新增" @btnClick="toggleDialog(true, 'create')" />

    <FiltersContainer>
      <el-select
        v-model="search.type"
        clearable
        placeholder="請選擇群組性質"
        @change="getSalesGroupInfo()"
      >
        <el-option
          v-for="item in salesGroupTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-input
        v-model="search.name"
        clearable
        suffix-icon="el-icon-search"
        placeholder="請輸入群組名稱"
        @change="getSalesGroupInfo()"
      />
    </FiltersContainer>

    <el-table
      v-loading="loading.page"
      class="sales-group__table"
      empty-text="暫無數據"
      :data="salesGroupList"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <EmptyBlock slot="empty" />

      <el-table-column
        prop="type"
        label="性質"
      >
        <template slot-scope="scope">
          {{ salesGroupTypeOptions[scope.row.type].label }}
        </template>
      </el-table-column>

      <el-table-column
        prop="name"
        label="名稱"
      />

      <el-table-column
        prop="order"
        label="排序"
      />

      <el-table-column
        label="操作"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="setFormData(scope.row);
                   toggleDialog(true, 'update');"
            @delete="toggleTargetId(scope.row.id);deleteDialog = true
            "
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="salesGroupListCount"
      @pageChange="getSalesGroupInfo()"
    />

    <!-- Dialog -->
    <el-dialog
      :title="dialogType === 'create' ? '新增群組' : '編輯群組'"
      :visible.sync="showDialog"
      width="600px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="formRef"
        :model="formData"
        :rules="rules"
      >
        <el-form-item label="性質" prop="type">
          <el-select
            v-model="formData.type"
            placeholder="請選擇性質"
          >
            <el-option
              v-for="item in salesGroupTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="名稱" prop="name">
          <el-input
            v-model="formData.name"
            placeholder="請輸入名稱"
            clearable
          />
        </el-form-item>
        <el-form-item label="排序" prop="order">
          <el-input
            v-model="formData.order"
            placeholder="請輸入排序"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button
          :loading="loading.button"
          @click="toggleDialog()"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          style="margin-left: 12px"
          :loading="loading.button"
          @click="submitFormData()"
        >
          確認
        </el-button>
      </template>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="警示"
      content="確定要刪除？刪除後將無法復原"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteSalesGroup();"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  GetSalesGroup,
  GetSalesGroupCount,
  PostSalesGroup,
  PutSalesGroup,
  DeleteSalesGroup,
} from '@/api/sales'
import {
  salesGroupTypeOptions,
} from '@/config/sales'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import { convertEmptyToUndefined } from '@/utils/helper'
import { pageStartIndex } from '@/utils/table'
import notifyMessage from '@/config/notifyMessage'
import DeleteDialog from '@/components/Dialog/DeleteDialog'

export default {
  name: 'SalesGroupSetting',

  components: {
    EmptyBlock,
    DeleteDialog,
  },

  data () {
    return {
      salesGroupTypeOptions,
      loading: {
        page: false,
        button: false,
      },
      search: {
        type: '',
        name: '',
      },
      salesGroupList: [],
      salesGroupListCount: 0,
      deleteDialog: false,
      showDialog: false,
      dialogType: '',
      targetId: '',
      formData: {
        type: '',
        name: '',
        order: 100,
      },
      rules: {
        type: noEmptyRules('請選擇群組性質'),
        name: noEmptyRules('請輸入群組名稱'),
        order: [isDigitRules(), noEmptyRules('請輸入排序'), rangeRules()],
      },
      tableOptions: {
        page: 1,
        pageLimit: 10,
      },
    }
  },

  computed: {
    ...mapGetters([
      'shop',
    ]),

    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
  },

  watch: {
    showDialog (current) {
      if (!current) this.clearFormData()
    },
  },

  async mounted () {
    this.loading.page = true
    await this.getSalesGroupInfo()
    this.loading.page = false
  },

  methods: {
    async getSalesGroupInfo () {
      this.loading.page = true
      await this.getSalesGroupCount()
      await this.getSalesGroup()
      this.loading.page = false
    },

    async getSalesGroup () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit

      const res = await GetSalesGroup({
        shopId: this.shop,
        start: startIndex,
        limit,
        type: convertEmptyToUndefined(this.search.type),
        name: convertEmptyToUndefined(this.search.name),
      })

      this.salesGroupList = res
    },

    async getSalesGroupCount () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit

      const res = await GetSalesGroupCount({
        shopId: this.shop,
        type: convertEmptyToUndefined(this.search.type),
        name: convertEmptyToUndefined(this.search.name),
      })

      this.salesGroupListCount = res.count
    },

    async createSalesGroup () {
      try {
        PostSalesGroup({
          shopId: this.shop,
          type: this.formData.type,
          name: this.formData.name,
          order: Number(this.formData.order),
        })
        this.$message.success(notifyMessage.createSuccess)
      } catch (error) {
        this.$message.error(error)
      }
    },

    async updateSalesGroup () {
      try {
        await PutSalesGroup({
          shopId: this.shop,
          id: this.targetId,
          type: this.formData.type,
          name: this.formData.name,
          order: Number(this.formData.order),
        })
        this.$message.success(notifyMessage.updateSuccess)
      } catch (error) {
        this.$message.error(error)
      }
    },

    async deleteSalesGroup () {
      this.loading.page = true
      try {
        await DeleteSalesGroup({
          shopId: this.shop,
          id: this.targetId,
        })
        this.$message.success(notifyMessage.deleteSuccess)
        this.deleteDialog = false
        await this.getSalesGroupInfo()
      } catch (error) {
        this.$message.error(error)
      }
      this.loading.page = false
    },

    toggleDialog (show = false, type = 'create') {
      this.showDialog = show
      this.dialogType = type
    },

    toggleTargetId (id = '') {
      this.targetId = id
    },

    clearFormData () {
      this.toggleTargetId()
      this.formData = {
        type: '',
        name: '',
        order: 100,
      }
      this.$refs.formRef.resetFields()
    },

    setFormData (row) {
      this.toggleTargetId(row.id)
      this.formData = { ...row }
    },

    async submitFormData () {
      await this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          this.$message.error('資料未填寫完整')
          return false
        }
        this.loading.button = true

        if (this.dialogType === 'create') await this.createSalesGroup()
        if (this.dialogType === 'update') await this.updateSalesGroup()

        this.loading.button = false
        this.showDialog = false

        await this.getSalesGroupInfo()
      })
    },
  },
}
</script>

<style scoped>
.sales-group__search {
  display: flex;
  gap: 0 8px;
  margin-top: 20px;
}

.sales-group__table {
  margin-top: 20px;
}
</style>
