export const exportFormatOptions = [
  {
    label: 'Excel',
    value: 'xlsx',
  },
  {
    label: 'PDF',
    value: 'pdf',
  },
]

export const salesRecordStatusOptions = {
  complete: '已完成',
  invalid: '已作廢',
}

export const salesGroupTypeOptions = {
  salesCategory: {
    value: 'salesCategory',
    label: '銷售類別',
  },
  salesProduct: {
    value: 'salesProduct',
    label: '銷售產品',
  },
  salesUnit: {
    value: 'salesUnit',
    label: '銷售人員',
  },
}

export const salesProductPropertyOptions = {
  product: {
    value: 'product',
    label: '一般產品',
  },
  ticket: {
    value: 'ticket',
    label: '堂票',
  },
  deposit: {
    value: 'deposit',
    label: '儲值金',
  },
}
